export const safeParseJson = (jsonString: string | undefined) => {
  if (jsonString) {
    try {
      return JSON.parse(jsonString)
    } catch {
      // Do nothing
    }
  }
  return {}
}
