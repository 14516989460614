import Script from 'next/script'

export const ResizeNotifier = () => {
  return (
    <Script
      id="motif-resize-notifier"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
        window.parent.postMessage({
          name: 'window_height',
          height: document.body.scrollHeight
        }, '*');
        setTimeout(() => {
          window.parent.postMessage({
            name: 'window_height',
            height: document.body.scrollHeight
          }, '*');
        }, 1000)`,
      }}
    />
  )
}
