import * as MDX from '@mdx-js/react'
import React, { useEffect, useMemo, useState } from 'react'
import './idle-callback-polyfill'

// requestIdleCallback types found here:
// https://github.com/microsoft/TypeScript/issues/21309
type RequestIdleCallbackHandle = number
type RequestIdleCallbackOptions = {
  timeout?: number
}
type RequestIdleCallbackDeadline = {
  readonly didTimeout: boolean
  timeRemaining: () => number
}

declare global {
  interface Window {
    requestIdleCallback: (
      callback: (deadline: RequestIdleCallbackDeadline) => void,
      opts?: RequestIdleCallbackOptions
    ) => RequestIdleCallbackHandle
    cancelIdleCallback: (handle: RequestIdleCallbackHandle) => void
  }
}

export type MDXComponentProps = any & {
  code?: string
  exportName?: string
  components?: React.ComponentProps<typeof MDX.MDXProvider>['components']
  lazy?: boolean
  isPreview: boolean
}

const MDXComponent = ({
  code,
  scope,
  Component,
  components = {},
  exportName = 'default',
  lazy,
  children,
  ...props
}: MDXComponentProps) => {
  const [isReadyToRender, setIsReadyToRender] = useState(
    !lazy || typeof window === 'undefined'
  )

  // If we're on the client side and `lazy` is set to true, we hydrate the
  // MDX content inside requestIdleCallback, allowing the page to get to
  // interactive quicker, but the MDX content to hydrate slower.
  useEffect(() => {
    if (lazy) {
      const handle = window.requestIdleCallback(() => {
        setIsReadyToRender(true)
      })
      return () => window.cancelIdleCallback(handle)
    }
  }, [lazy])

  const Content: any = useMemo(() => {
    if (Component) {
      return Component
    }
    const fullScope = Object.assign(
      {
        opts: {
          ...MDX,
        },
      },
      scope
    )
    const keys = Object.keys(fullScope)
    const values = Object.values(fullScope)
    const hydrateFn = Reflect.construct(Function, keys.concat(`${code}`))
    return hydrateFn.apply(hydrateFn, values)?.[exportName]
  }, [Component, scope, code, exportName])

  if (!isReadyToRender) {
    // If we're not ready to render, return an empty div to preserve
    // SSR'd markup
    return (
      <div dangerouslySetInnerHTML={{ __html: '' }} suppressHydrationWarning />
    )
  }

  const content = (
    <MDX.MDXProvider components={components}>
      <Content {...props}>{children}</Content>
    </MDX.MDXProvider>
  )

  // If lazy = true, we need to render a wrapping div to preserve the
  // same markup structure that was SSR'd
  return lazy ? <div>{content}</div> : content
}

export default MDXComponent
