import Head from 'next/head'
import { useMemo } from 'react'

import { removeFileExtensionIfMDOrMdoc } from '../../file-types'

interface TitleProps {
  filename: string | undefined
  frontmatter: any | undefined
  configTitle: string | undefined
  configHeadTitle: string | undefined
  configTitleTemplate: string | undefined
}

// Title is computed as follows:
// - If present in the frontmatter, use
// - If config has a title key, use
// - If config head has a <title>, use
// - Otherwise, use filename
// Finally:
// - Wrap with template if present
const getTitles = (
  filename: string | undefined,
  configTitle: string | undefined,
  frontmatter: { [key: string]: any },
  configHeadTitle: string | undefined,
  configTitleTemplate: string | undefined
): {
  title: string | undefined
  twitterTitle: string | undefined
  ogTitle: string | undefined
} => {
  let title: string | undefined = undefined
  if (frontmatter?.title) {
    title = frontmatter?.title
  } else if (configTitle) {
    title = configTitle
  } else if (configHeadTitle) {
    title = configHeadTitle
  } else if (filename) {
    title = removeFileExtensionIfMDOrMdoc(filename)
  }

  if (configTitleTemplate && !frontmatter?.noTitleTemplate) {
    title = configTitleTemplate.split('%s').join(title)
  }

  const twitterTitle = frontmatter?.meta?.['twitter:title'] ?? title
  const ogTitle = frontmatter?.meta?.['og:title'] ?? title

  return { title, twitterTitle, ogTitle }
}

const Title = ({
  filename,
  frontmatter,
  configTitle,
  configHeadTitle,
  configTitleTemplate,
}: TitleProps): JSX.Element => {
  const { title, twitterTitle, ogTitle } = useMemo(
    () =>
      getTitles(
        filename,
        configTitle,
        frontmatter,
        configHeadTitle,
        configTitleTemplate
      ),
    [filename, frontmatter, configTitle, configHeadTitle, configTitleTemplate]
  )

  // `title` and `meta` need to be direct children of next/head.
  // Cf. https://nextjs.org/docs/api-reference/next/head
  return (
    <Head>
      <title key="title">{title}</title>
      {!frontmatter?.meta?.['twitter:title'] && (
        <meta key="twitter:title" name="twitter:title" content={twitterTitle} />
      )}
      {!frontmatter?.meta?.['og:title'] && (
        <meta key="og:title" property="og:title" content={ogTitle} />
      )}
    </Head>
  )
}

export default Title
