import dynamic from 'next/dynamic'
import type { FC, ReactNode } from 'react'
import React, { useMemo, useState } from 'react'

import AnalyticsTrackers from '../components/AnalyticsTrackers'
import GlobalJSXStyle from '../components/GlobalJSXStyle'
import Head from '../components/head/Head'
import MDXComponent from '../components/MDXComponent'
import { ResizeNotifier } from '../components/ResizeNotifier'
import { shims } from '../components/shims'
import { useHasParam } from '../hooks/use-has-param'
import type { PageProps } from './generate-static-props'
import { getPageComponent } from './get-page-component'
import DefaultPasswordPage from './password'
import { getScope } from './scope'
import { safeParseJson } from './utils'

export type TemplateProps = { children?: React.ReactNode } & Partial<
  Pick<
    PageProps,
    | 'analyticsInfo'
    | 'configFaviconHref'
    | 'configHead'
    | 'configTitle'
    | 'configTitleTemplate'
    | 'css'
    | 'domain'
    | 'fileId'
    | 'filename'
    | 'includeBranding'
    | 'isCustomDomain'
    | 'isPasswordProtected'
    | 'isPreview'
    | 'mdxCode'
    | 'notifyResize'
    | 'path'
    | 'projectId'
    | 'serializedFiles'
    | 'serializedFrontmatter'
    | 'templateCode'
    | 'slugComponents'
  >
>

const Noop = () => <></>

// Must be loaded client-side as it depends on window
const MotifFooter = dynamic(() => import('../components/MotifFooter'), {
  ssr: false,
})

// Store as a global variable, don't compute on each render cycle
// as this will cause a rerender of the component.
// const useScope = create(() => getScope(Noop, false))

const scope = getScope(Noop)

type WrapperProps = Omit<
  TemplateProps,
  'path' | 'isPreview' | 'slugComponents'
> & {
  jsMetaAndFrontmatter: any
  hasBrandlessParam: boolean
  domain?: string
  isCustomDomain?: boolean
  privateCode?: string
}

const Wrapper: FC<WrapperProps> = ({
  fileId,
  projectId,
  css,
  analyticsInfo,
  notifyResize,
  filename,
  configHead,
  configTitle,
  configTitleTemplate,
  configFaviconHref,
  includeBranding,
  jsMetaAndFrontmatter,
  hasBrandlessParam,
  domain,
  isCustomDomain,
  isPasswordProtected,
  children,
}) => {
  const [didValidatePassword, setDidValidatePassword] = useState(false)

  // Uses method similar to
  // https://github.com/vercel/next.js/blob/canary/examples/with-iron-session.
  // Cf. also https://github.com/vercel/next.js/discussions/10724
  if (isPasswordProtected && !didValidatePassword) {
    return (
      <>
        <GlobalJSXStyle css={css || undefined} />
        <DefaultPasswordPage
          fileId={fileId || undefined}
          projectId={projectId || undefined}
          domain={domain}
          isCustomDomain={isCustomDomain}
          onDidValidatePassword={setDidValidatePassword}
        />
      </>
    )
  }

  return (
    <>
      <GlobalJSXStyle css={css || undefined} />
      <AnalyticsTrackers
        frontmatter={jsMetaAndFrontmatter}
        info={analyticsInfo || undefined}
      />
      {notifyResize && <ResizeNotifier />}
      {/* Keep this component outside of NextHead, it takes care itself of
determining what goes inside a next/head, and what does not */}
      <Head
        filename={filename || undefined}
        frontmatter={jsMetaAndFrontmatter}
        configHead={configHead || undefined}
        configTitle={configTitle || undefined}
        configTitleTemplate={configTitleTemplate || undefined}
        configFaviconHref={configFaviconHref || undefined}
      />
      {children}
      {includeBranding && !hasBrandlessParam && <MotifFooter />}
    </>
  )
}

function Template(props: TemplateProps) {
  const {
    children,
    fileId,
    projectId,
    templateCode,
    path,
    filename,
    serializedFiles,
    serializedFrontmatter,
    mdxCode,
    analyticsInfo,
    notifyResize,
    configHead,
    configTitle,
    configTitleTemplate,
    configFaviconHref,
    includeBranding,
    domain,
    slugComponents,
    isCustomDomain,
    isPasswordProtected,
  } = props
  const templateFilesMeta = safeParseJson(serializedFiles || undefined)
  const frontmatter = safeParseJson(serializedFrontmatter || undefined)
  const hasBrandlessParam = useHasParam('brandless', 1)

  const PageComponent = useMemo(() => {
    if (!mdxCode) return
    // No need to pass the actual shimmed editor to a template
    return getPageComponent(mdxCode, Noop)
  }, [mdxCode])

  const meta = useMemo(() => {
    return { ...frontmatter, ...(PageComponent?.meta || {}) }
  }, [frontmatter, PageComponent?.meta])

  const jsMetaAndFrontmatter = {
    ...frontmatter,
    ...(PageComponent?.meta || {}),
  }

  if (!templateCode) {
    return (
      <Wrapper
        fileId={fileId}
        projectId={projectId}
        css={props.css}
        analyticsInfo={analyticsInfo}
        notifyResize={notifyResize}
        filename={filename}
        configHead={configHead}
        configTitle={configTitle}
        configTitleTemplate={configTitleTemplate}
        configFaviconHref={configFaviconHref}
        includeBranding={includeBranding}
        hasBrandlessParam={hasBrandlessParam}
        jsMetaAndFrontmatter={jsMetaAndFrontmatter}
        domain={domain}
        isCustomDomain={isCustomDomain}
        isPasswordProtected={isPasswordProtected}
      >
        {children}
      </Wrapper>
    )
  }

  return (
    <Wrapper
      fileId={fileId}
      projectId={projectId}
      css={props.css}
      analyticsInfo={analyticsInfo}
      notifyResize={notifyResize}
      filename={filename}
      configHead={configHead}
      configTitle={configTitle}
      configTitleTemplate={configTitleTemplate}
      configFaviconHref={configFaviconHref}
      includeBranding={includeBranding}
      hasBrandlessParam={hasBrandlessParam}
      jsMetaAndFrontmatter={jsMetaAndFrontmatter}
      domain={domain}
      isCustomDomain={isCustomDomain}
      isPasswordProtected={isPasswordProtected}
    >
      <MDXComponent
        exportName="Template"
        code={templateCode}
        components={shims(slugComponents || [])}
        scope={scope}
        meta={meta}
        path={path}
        filename={filename}
        files={templateFilesMeta}
        isPreview={false}
      >
        {children}
      </MDXComponent>
    </Wrapper>
  )
}

export const getTemplate = (page: ReactNode, templateProps: TemplateProps) => {
  return <Template {...(templateProps ?? {})}>{page}</Template>
}
