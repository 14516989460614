// Note: this component must be placed at the top of the
// component tree.
const GlobalJSXStyle = ({ css }: { css?: string }) => {
  if (!css) {
    return <></>
  }

  return (
    <style jsx global>
      {css}
    </style>
  )
}

export default GlobalJSXStyle
