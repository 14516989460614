import { useEffect, useState } from 'react'

export const useHasParam = (name: string, value: any) => {
  const [hasParam, setHasParam] = useState(false)

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams: any, prop: any) => searchParams.get(prop),
    })
    setHasParam(params[name] === value)
  }, [name, value])

  return hasParam
}
