import Script from 'next/script'
import type { FC } from 'react'

import type { AnalyticsInfo } from '../pages/generate-static-props'

interface AnalyticsTrackersProps {
  frontmatter?: any
  info?: AnalyticsInfo
}

const AnalyticsTrackers: FC<AnalyticsTrackersProps> = ({
  frontmatter,
  info,
}) => {
  const gaMeasurementId = frontmatter?.gaMeasurementId || info?.gaMeasurementId
  const gaTrackingId = frontmatter?.gaTrackingId || info?.gaTrackingId
  return (
    <>
      {gaMeasurementId && <GAMeasurementScript id={gaMeasurementId} />}
      {gaTrackingId && <GATrackingScript id={gaTrackingId} />}
    </>
  )
}

const safeEncodeGA = (id: string) => {
  // eslint-disable-next-line no-useless-escape
  return id.replace(/[^a-zA-Z0-9\-]/gi, '')
}

const GAMeasurementScript = ({ id }: { id: string }) => {
  const safeId = safeEncodeGA(id)
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${safeId}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${safeId}');
        `}
      </Script>
    </>
  )
}

const GATrackingScript = ({ id }: { id: string }) => {
  const safeId = safeEncodeGA(id)
  return (
    <>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', '${safeId}', 'auto');
          ga('send', 'pageview');
        `}
      </Script>
    </>
  )
}

export default AnalyticsTrackers
