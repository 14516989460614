import Head from 'next/head'

interface MetaListProps {
  frontmatter: { [key: string]: any }
  configFaviconHref: string | undefined
  configHeadFaviconHref: string | undefined
}

const Favicon = ({
  frontmatter,
  configFaviconHref,
  configHeadFaviconHref,
}: MetaListProps): JSX.Element => {
  const faviconHref =
    frontmatter.favicon ?? configFaviconHref ?? configHeadFaviconHref

  if (!faviconHref) {
    return <></>
  }

  // `link` needs to be direct children of next/head.
  // Cf. https://nextjs.org/docs/api-reference/next/head
  return (
    <Head>
      <link rel="shortcut icon" type="image/x-icon" href={faviconHref} />
    </Head>
  )
}

export default Favicon
