import * as __motif_headlessui_react from '@headlessui/react'
import __motif_markdoc from '@markdoc/markdoc'
import * as __motif_mdx_react from '@mdx-js/react'
import __motif_sindresorhus_slugify from '@sindresorhus/slugify'
import __motif_lodash_es from 'lodash-es'
import * as __motif_luxon from 'luxon'
import * as React from 'react'
import { useEffect, useReducer, useRef, useState } from 'react'
import * as ReactDOM from 'react-dom'
import * as ReactDOMClient from 'react-dom/client'

const baseScope = {
  React,
  ReactDOM,
  ReactDOMClient,
  __motif_lodash_es,
  __motif_luxon,
  __motif_markdoc,
  __motif_mdx_react,
  __motif_sindresorhus_slugify,
  __motif_headlessui_react,
}

const legacyScope = {
  // Legacy: some pages may contain references to useEffect and
  // other standard hooks without explicitly importing them.
  useEffect,
  useState,
  useRef,
  useReducer,
}

export const getScope = (ShimmedEditor: any | undefined) => {
  return {
    ...baseScope,
    ...(ShimmedEditor ? { __motif_editor: ShimmedEditor } : {}),
    ...legacyScope,
  }
}
