// Keep this file light and without imports, as it's used in workers
// (larger imports will slow down cold start).

export type FileType =
  | 'mdx'
  | 'mdoc'
  | 'md'
  | 'js'
  | 'jsx'
  | 'ts'
  | 'tsx'
  | 'json'
  | 'css'
  | 'txt'
  | 'csv'
  | 'tsv'

export const supportedExtensions = [
  'md',
  'mdx',
  'mdoc',
  'js',
  'jsx',
  'ts',
  'tsx',
  'json',
  'css',
  'txt',
  'csv',
  'tsv',
]

export const hasSupportedExtension = (name: string) => {
  for (const extension of supportedExtensions) {
    if (name.endsWith(`.${extension}`)) {
      return true
    }
  }
  return false
}

// If name has a supported extension, e.g. name.csv or name.mdx,
// return the extension. Otherwise, e.g. name.abc, return undefined.
export const getExtensionIfSupported = (name: string) => {
  for (const extension of supportedExtensions) {
    if (name.endsWith(`.${extension}`)) {
      return extension
    }
  }
  return undefined
}

export const isJSVariant = (fileType: FileType): boolean => {
  return (
    fileType === 'js' ||
    fileType === 'ts' ||
    fileType === 'jsx' ||
    fileType === 'tsx'
  )
}

const getFileExtension = (name: string | undefined): string | undefined => {
  const components = name?.split('.')
  if (!components || components?.length <= 1) {
    return undefined
  }
  return components.slice(-1)[0]
}

export const removeFileExtension = (name: string): string => {
  const parts = name?.split('.')
  if (parts && parts.length >= 2) {
    return parts.slice(0, -1).join('.')
  }
  return name
}

export const removeFileExtensionIfMD = (name: string): string => {
  const extension = getFileExtension(name)
  if (extension === 'md' || extension === 'mdx') {
    return name?.split('.').slice(0, -1).join('.')
  }
  return name
}

export const removeFileExtensionIfMDOrMdoc = (name: string): string => {
  const extension = getFileExtension(name)
  if (extension === 'md' || extension === 'mdx' || extension === 'mdoc') {
    return name.split('.').slice(0, -1).join('.')
  }
  return name
}

export const addMDXFileExtensionIfNone = (name: string): string => {
  const extension = getFileExtension(name)
  if (!extension) {
    return `${name}.mdx`
  }
  return name
}

export const getFileType = (name: string): FileType => {
  const extension = getFileExtension(name)
  switch (extension) {
    case 'js':
      return 'js'
    case 'jsx':
      return 'jsx'
    case 'ts':
      return 'ts'
    case 'tsx':
      return 'tsx'
    case 'json':
      return 'json'
    case 'css':
      return 'css'
    case 'txt':
      return 'txt'
    case 'csv':
      return 'csv'
    case 'tsv':
      return 'tsv'
    case 'mdoc':
      return 'mdoc'
    default:
      return 'mdx'
  }
}

export const getFileExtensionForType = (fileType: FileType): string => {
  switch (fileType) {
    case 'js':
      return 'js'
    case 'jsx':
      return 'jsx'
    case 'ts':
      return 'ts'
    case 'tsx':
      return 'tsx'
    case 'json':
      return 'json'
    case 'css':
      return 'css'
    case 'txt':
      return 'txt'
    case 'csv':
      return 'csv'
    case 'mdoc':
      return 'mdoc'
    default:
      return 'mdx'
  }
}

export const isDocType = (filetype: FileType): boolean => {
  return filetype === 'mdx' || filetype === 'mdoc' || filetype === 'md'
}

export const mayContainCSS = (filetype: FileType): boolean => {
  return (
    filetype === 'mdx' ||
    filetype === 'mdoc' ||
    filetype === 'js' ||
    filetype === 'jsx' ||
    filetype === 'ts' ||
    filetype === 'tsx' ||
    filetype === 'json' ||
    filetype === 'css'
  )
}

export const transpiledCodeRequiresRuntime = (filetype: FileType): boolean => {
  // Used to determine whether the transpiled code is invoking
  // runtime code like React.createElement. This is currently the case
  // for MDX, JSX and TSX files.
  return filetype === 'mdx' || filetype === 'jsx' || filetype === 'tsx'
}

export const supportsImports = (filetype: FileType): boolean => {
  return filetype === 'mdx' || isJSType(filetype)
}

export const supportsJSXComponents = (filetype: FileType): boolean => {
  return filetype === 'mdx' || filetype === 'jsx' || filetype === 'tsx'
}

export const supportsFrontmatter = (filetype: FileType): boolean => {
  return filetype === 'mdx' || filetype === 'mdoc' || filetype === 'md'
}

export const supportsPreview = (filetype: FileType): boolean => {
  return isDocType(filetype)
}

export const isJSType = (filetype: FileType): boolean => {
  return (
    filetype === 'js' ||
    filetype === 'jsx' ||
    filetype === 'ts' ||
    filetype === 'tsx'
  )
}

export const addFileExtension = (
  nameWithoutExtension: string,
  fileType: FileType
): string => {
  return `${nameWithoutExtension}.${getFileExtensionForType(fileType)}`
}
