import type { FC, ReactNode } from 'react'
import { useMemo } from 'react'

import type { FileError } from '@motif/errors'

import MarkdocComponent from '../components/MarkdocComponent'
import MDXComponent from '../components/MDXComponent'
import { shims } from '../components/shims'
import type { PageProps } from '../pages/generate-static-props'
import DefaultErrorPage from './404'
import { getPageComponent } from './get-page-component'
import { getScope } from './scope'
import type { TemplateProps } from './template'
import { safeParseJson } from './utils'

const Slug: FC<PageProps> & {
  getTemplate?: (page: ReactNode, templateProps: TemplateProps) => JSX.Element
} = ({
  mdxCode,
  serializedMarkdocNode,
  markdocComponentsCode,
  filename,
  serializedFiles,
  serializedFrontmatter,
  ShimmedEditor,
  ErrorPage,
  slug,
  error,
  path,
  slugComponents,
}) => {
  const templateFilesMeta = safeParseJson(serializedFiles || undefined)
  const frontmatter = safeParseJson(serializedFrontmatter || undefined)

  const PageComponent = useMemo(() => {
    if (!mdxCode) return
    return getPageComponent(mdxCode, ShimmedEditor)
  }, [mdxCode, ShimmedEditor])

  const jsMetaAndFrontmatter = {
    ...frontmatter,
    ...(PageComponent?.meta || {}),
  }

  const markdocNode = useMemo(() => {
    try {
      if (serializedMarkdocNode) {
        return JSON.parse(serializedMarkdocNode)
      }
    } catch {
      // Do nothing
    }
    return undefined
  }, [serializedMarkdocNode])

  if (typeof document !== 'undefined') {
    // Important: even if the frontmatter/meta does not specify a
    // body class, we still need to set the body className, because
    // otherwise, Next will keep the body's class from a previous
    // page, which could be different.
    document.body.className = jsMetaAndFrontmatter?.class?.body || ''
  }

  if (error) {
    let fileErrors: FileError[] = []
    if (error.serializedErrors) {
      try {
        fileErrors = JSON.parse(error.serializedErrors)
      } catch {
        // Do nothing
      }
    }

    const _ErrorPage = ErrorPage ?? DefaultErrorPage
    return (
      <_ErrorPage
        slug={slug || undefined}
        code={error.code}
        fileErrors={fileErrors}
      />
    )
  }

  return (
    <>
      {PageComponent?.default && (
        <MDXComponent
          Component={PageComponent?.default}
          meta={jsMetaAndFrontmatter}
          path={path}
          filename={filename}
          files={templateFilesMeta}
          components={shims(slugComponents)}
        />
      )}
      {markdocNode && (
        <MarkdocComponent
          node={markdocNode}
          markdocComponentsCode={markdocComponentsCode}
          scope={getScope(ShimmedEditor)}
        />
      )}
    </>
  )
}

export default Slug
