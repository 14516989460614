import md5 from 'js-md5'
import { NextSeo } from 'next-seo'
import Link from 'next/link'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'

import type { File, Project } from '@motif/types'

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

const LOCAL_STORAGE_BASIC_AUTH_PASSWORD_KEY_PREFIX =
  'LOCAL_STORAGE_BASIC_AUTH_PASSWORD_KEY'

const getStoredMd5Password = (fileId?: File['id']) => {
  if (typeof window === 'undefined' || !fileId) {
    return undefined
  }

  return window.localStorage.getItem(
    `${LOCAL_STORAGE_BASIC_AUTH_PASSWORD_KEY_PREFIX}-${fileId}`
  )
}

const setStoredMd5Password = (fileId: File['id'], md5Password: string) => {
  if (typeof window === 'undefined' || !fileId) {
    return undefined
  }

  return window.localStorage.setItem(
    `${LOCAL_STORAGE_BASIC_AUTH_PASSWORD_KEY_PREFIX}-${fileId}`,
    md5Password
  )
}

const setLastSuccessfullAttempt = (fileId: File['id'], date: number) => {
  if (typeof window === 'undefined') {
    return undefined
  }

  return window.localStorage.setItem(
    `${LOCAL_STORAGE_BASIC_AUTH_PASSWORD_KEY_PREFIX}-date-${fileId}`,
    JSON.stringify(date)
  )
}

const getLastSuccessfullAttempt = (fileId: File['id']): number | undefined => {
  if (typeof window === 'undefined') {
    return undefined
  }

  try {
    const storedDate = window.localStorage.getItem(
      `${LOCAL_STORAGE_BASIC_AUTH_PASSWORD_KEY_PREFIX}-date-${fileId}`
    )
    if (storedDate) {
      return parseInt(storedDate)
    }
  } catch {
    // Do nothing
  }
  return undefined
}

const LoadingDots: FC = () => {
  return (
    <>
      <style jsx>{`
        .loading {
          display: inline-flex;
          align-items: center;
        }

        .loading .spacer {
          margin-right: 2px;
        }

        .loading span {
          animation-name: blink;
          animation-duration: 1.4s;
          animation-iteration-count: infinite;
          animation-fill-mode: both;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          display: inline-block;
          margin: 0 2px;
        }

        .loading span:nth-of-type(2) {
          animation-delay: 0.2s;
        }

        .loading span:nth-of-type(3) {
          animation-delay: 0.4s;
        }

        @keyframes blink {
          0% {
            opacity: 0.2;
          }
          20% {
            opacity: 1;
          }
          100% {
            opacity: 0.2;
          }
        }
      `}</style>
      <span className="loading">
        <span style={{ backgroundColor: '#a3a3a3' }} />
        <span style={{ backgroundColor: '#a3a3a3' }} />
        <span style={{ backgroundColor: '#a3a3a3' }} />
      </span>
    </>
  )
}

// Uses method similar to
// https://github.com/vercel/next.js/blob/canary/examples/with-iron-session.
// Cf. also https://github.com/vercel/next.js/discussions/10724
export interface DefaultPasswordPageProps {
  domain?: string
  isCustomDomain?: boolean
  projectId?: Project['id']
  fileId?: File['id']
  onDidValidatePassword: (validated: boolean) => void
}

const DefaultPasswordPage: FC<DefaultPasswordPageProps> = ({
  domain,
  isCustomDomain,
  projectId,
  fileId,
  onDidValidatePassword,
}) => {
  const [didEnterText, setDidEnterText] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [state, setState] = useState<
    'initial_check' | 'user_submission' | undefined
  >('initial_check')

  const validatePassword = useCallback(
    async (encryptedPassword: string, initialCheck: boolean) => {
      if (!fileId) {
        setState(undefined)
        return
      }

      const lastSuccessfullAttempt = getLastSuccessfullAttempt(fileId)
      if (
        lastSuccessfullAttempt &&
        Date.now() - lastSuccessfullAttempt < FIVE_MINUTES_IN_MS
      ) {
        onDidValidatePassword(true)
        setLastSuccessfullAttempt(fileId, Date.now())
        setState(undefined)
      }

      setState(initialCheck ? 'initial_check' : 'user_submission')

      const res = await fetch(`/api/auth/check-page-access`, {
        method: 'POST',
        body: JSON.stringify({
          encryptedPassword,
          projectId,
          fileId,
        }),
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      })

      if (!res.ok) {
        setState(undefined)
        setError('Incorrect password. Please try again.')
        return
      }

      onDidValidatePassword(true)
      setStoredMd5Password(fileId, encryptedPassword)
      setLastSuccessfullAttempt(fileId, Date.now())
      setState(undefined)
    },
    [fileId, projectId, onDidValidatePassword]
  )

  useEffect(() => {
    // Initial state
    const storedMd5Password = getStoredMd5Password(fileId)

    if (storedMd5Password) {
      validatePassword(storedMd5Password, true)
    } else {
      setState(undefined)
    }
  }, [fileId, validatePassword])

  const domainName = isCustomDomain ? domain : `${domain}.motif.land`

  return (
    <>
      <style jsx>{`
        .main {
          padding-left: 12px;
          padding-right: 12px;
        }
        .container {
          padding-top: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          gap: 0.5rem;
          background-color: #fafafa;
          border: 1px solid #e4e4e7;
          border-radius: 0.5rem;
          padding-left: 2rem;
          padding-right: 2rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          margin-top: 100px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        .container h2 {
          text-align: center;
          font-weight: 600;
          font-size: 18px;
        }

        p {
          color: #737373;
          text-align: center;
          font-size: 14px;
        }

        p.checking {
          margin-top: 100px;
        }

        p.message {
          margin-bottom: 10px;
        }

        p.error {
          color: #dc2626;
          font-size: 12px;
        }

        .container a {
          color: #0ea5e9;
          text-align: center;
          font-size: 14px;
          margin-top: 6px;
        }

        .container input {
          color: #171717;
          background: #fff;
          border: 1px solid #e4e4e7;
          border-radius: 0.375rem;
          padding-left: 0.625rem;
          padding-right: 0.625rem;
          padding-top: 0.375rem;
          padding-bottom: 0.375rem;
          outline: 2px solid transparent;
          outline-offset: 2px;
          font-size: 14px;
        }

        .container input:disabled {
          color: #a3a3a3;
          cursor: not-allowed;
        }

        .container button {
          background: #171717;
          border-radius: 0.375rem;
          border: 1px solid #171717;
          padding-left: 0.625rem;
          padding-right: 0.625rem;
          padding-top: 0.375rem;
          padding-bottom: 0.375rem;
          outline: 2px solid transparent;
          outline-offset: 2px;
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          transition-property: opacity;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;
        }

        .container button:disabled {
          background: #e5e5e5;
          border: 1px solid #e5e5e5;
          cursor: not-allowed;
        }

        .container button:hover {
          opacity: 0.9;
        }

        .dots-container {
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>
      <NextSeo title={`Access protected - ${domainName}`} />
      {state === 'initial_check' ? (
        <p className="checking">Checking access...</p>
      ) : (
        <div className="main">
          <form
            className="container"
            onSubmit={(e) => {
              e.preventDefault()
              setError(null)
              validatePassword(md5(password), false)
            }}
          >
            <h2>Access protected</h2>
            <p className="message">Please enter a password</p>
            {error && <p className="error">{error}</p>}
            <input
              value={password}
              onChange={(e) => {
                setDidEnterText(true)
                setPassword(e.target.value)
              }}
              type="password"
              autoFocus
              disabled={state === 'user_submission'}
            />
            <button
              type="submit"
              style={{
                position: 'relative',
              }}
              disabled={
                didEnterText && (state === 'user_submission' || !password)
              }
            >
              <span
                style={{
                  opacity: state === 'user_submission' ? 0 : 1,
                }}
              >
                Continue
              </span>
              {state === 'user_submission' && (
                <div className="dots-container">
                  <LoadingDots />
                </div>
              )}
            </button>
            {domain && <Link href="/">Back to {domainName}</Link>}
          </form>
        </div>
      )}
    </>
  )
}

export default DefaultPasswordPage
