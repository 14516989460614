import { NextSeo } from 'next-seo'
import Link from 'next/link'
import type { FC } from 'react'

import type { FileError } from '@motif/errors'

export interface ErrorPageProps {
  slug?: string[]
  code: string
  fileErrors: FileError[]
}

const DefaultErrorPage: FC<ErrorPageProps> = () => {
  return (
    <>
      <NextSeo title="Page not found" />
      <div className="flex h-screen w-full flex-col items-center justify-center">
        <div className="flex flex-row p-8 text-xl">
          <h2 className="mr-1 font-bold text-slate-900">404</h2>
          <p className="ml-1 text-neutral-500">Page not found</p>
        </div>
        <Link
          href="/"
          className="subtleUnderline mt-3 text-sm text-sky-500 hover:text-slate-700"
        >
          Back to Motif
        </Link>
      </div>
    </>
  )
}

export default DefaultErrorPage
