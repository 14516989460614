import type { Size } from '@motif/types'

// This function looks at the img src and extracts size dimenstions
// from it. For context, when uploading an image via Motif, we
// compute its dimensions and add it to the file upload URL. This
// info is required for using e.g. next/image.
export const imageUrlToDimensions = (src: string): Size | undefined => {
  const filename = src?.split('/')[src?.split('/').length - 1]
  let sizeInfo: string | string[] = filename?.split('-')[0]
  let size: Size | undefined = undefined

  if (sizeInfo?.startsWith('i')) {
    sizeInfo = sizeInfo.slice(1)
    sizeInfo = sizeInfo.split('x')
    if (sizeInfo.every((n) => !isNaN(Number(n)))) {
      size = {
        width: Number(sizeInfo[0]),
        height: Number(sizeInfo[1]),
      }
    }
  }

  return size
}
