import Head from 'next/head'

interface MetaListProps {
  frontmatter: { [key: string]: any }
  configHeadMetaPropertyTags: { property: string; content: string }[]
  configHeadMetaNameTags: { name: string; content: string }[]
}

const MetaList = ({
  frontmatter,
  configHeadMetaPropertyTags,
  configHeadMetaNameTags,
}: MetaListProps): JSX.Element => {
  // If any of the following is present in the frontmatter, use it as
  // for meta description:
  // - description
  // - meta.description
  // - meta["og:description"]
  const frontmatterDescription =
    frontmatter?.description ||
    frontmatter?.meta?.description ||
    frontmatter?.meta?.['og:description']

  const configHeadMetaHasTwitterCardProperty = configHeadMetaPropertyTags.find(
    (t) => {
      return t.property === 'twitter:card'
    }
  )

  const frontmatterMeta = {
    // Set default values for the following tags. Will be overwritten
    // if set explicitly in `frontmatter.meta`.
    'og:description': frontmatterDescription,
    'og:title': frontmatter?.title,
    'twitter:title': frontmatter?.title,
    'twitter:image': frontmatter?.meta?.['og:image'],
    ...(!configHeadMetaHasTwitterCardProperty
      ? { 'twitter:card': 'summary' }
      : {}),
    ...(frontmatter?.meta || {}),
  }

  const frontmatterMetaKeys = Object.keys(frontmatterMeta)

  const configMetaHeadPropertyTagsExcludingFrontmatter =
    configHeadMetaPropertyTags.filter((t) => {
      if (t.property === 'description' && frontmatterDescription) {
        return false
      }
      return !frontmatterMetaKeys.includes(t.property)
    })

  const configHeadMetaNameTagsExcludingFrontmatter =
    configHeadMetaNameTags.filter((t) => {
      if (t.name === 'description' && frontmatterDescription) {
        return false
      }
      return !frontmatterMetaKeys.includes(t.name)
    })

  // `meta` needs to be direct children of next/head.
  // Cf. https://nextjs.org/docs/api-reference/next/head
  return (
    <Head>
      {frontmatterDescription && (
        <>
          <meta
            key="description"
            property="description"
            content={frontmatterDescription}
          />
          <meta
            key="description"
            name="description"
            content={frontmatterDescription}
          />
        </>
      )}
      {frontmatterMetaKeys
        .filter((k) => !!frontmatterMeta[k])
        .map((k) => {
          if (k === 'description' && frontmatterDescription) {
            // Ignore frontmatter.meta.description if
            // frontmatter.description exists.
            return <></>
          }

          return (
            <meta
              key={`frontmatter-meta-${k}`}
              property={k}
              content={frontmatterMeta[k]}
            />
          )
        })}
      {configMetaHeadPropertyTagsExcludingFrontmatter.map(
        ({ property, content }) => {
          return (
            <meta
              key={`config-meta-property-${property}`}
              property={property}
              content={content}
            />
          )
        }
      )}
      {configHeadMetaNameTagsExcludingFrontmatter.map(({ name, content }) => {
        return (
          <meta
            key={`config-meta-name-${name}`}
            name={name}
            content={content}
          />
        )
      })}
    </Head>
  )
}

export default MetaList
