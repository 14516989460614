import { isAbsolute, resolve } from '@protobufjs/path'
import type { ImageProps } from 'next/image'
import Image from 'next/image'
import Link from 'next/link'

import { MOTIF_A_TAG, MOTIF_IMG_TAG } from '../constants'
import { imageUrlToDimensions } from '../utils/image'

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <rect width="${w}" height="${h}" fill="#ffffff00" />
</svg>`

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str)

const imageLoader = ({ src, width }: { src: string; width: number }) => {
  if (src.includes('https://res.cloudinary.com/')) {
    // Get image original width, to make sure we don't get a scaled-up
    // version, which would add unneccessary cost to us, and increase the
    // size of the downloaded image.
    const originalDimensions = imageUrlToDimensions(src)
    if (originalDimensions && width > originalDimensions.width) {
      width = originalDimensions.width
    }
    return src.replace('/image/upload/', `/image/upload/w_${width}/`)
  }
  return src
}

const NextImageOrImg = ({
  alt,
  src,
  className,
  style,
  ...rest
}: Omit<ImageProps, 'src'> & { src: string }) => {
  const _alt = alt ?? ''

  const sizeInfo = imageUrlToDimensions(src)

  if (sizeInfo) {
    return (
      <Image
        loader={imageLoader}
        src={src}
        width={sizeInfo.width}
        height={sizeInfo.height}
        alt={_alt}
        className={className}
        style={style}
        placeholder="blur"
        blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
        {...rest}
      />
    )
  }

  return (
    <img
      src={src}
      alt={_alt}
      className={className}
      style={style}
      loading="lazy"
      {...rest}
    />
  )
}

export const resolveHref = (href: string | undefined, slug: string[]) => {
  /**
   * This handles relative URL's in user content, making sure that next/link
   * does not resolve the URL's based on the filesystem, which would cause links
   * to have href's like /_projectdomain/[domain]/<href>
   */
  const slugPath = (slug as string[])?.join('/') ?? ''
  if (!href) {
    return '/' + slugPath
  }

  const isHash = href.startsWith('#')
  if (isHash) {
    if (slugPath.length === 0) {
      return href
    } else {
      return '/' + slugPath + href
    }
  } else {
    // Prevent the empty path from resulting in "//"
    const basePath = '/' + slugPath + (slug.length > 0 ? '/' : '')
    return isAbsolute(href) ? href : resolve(basePath, href)
  }
}

const LinkOrAnchor =
  (slugComponents: string[]) =>
  // eslint-disable-next-line react/display-name
  ({ href, ...props }: any) => {
    // Sometimes, users may accidentally add non-string props, and
    // this shouldn't crash the build process.
    if (!href) {
      return <a {...props} />
    } else if (typeof href !== 'string') {
      return <a href={href} {...props} />
    }

    const resolvedHref = resolveHref(href, slugComponents)

    return <Link href={resolvedHref} {...props} />
  }

export const shims = (slugComponents: string[]) => ({
  img: NextImageOrImg,
  a: LinkOrAnchor(slugComponents),
  [MOTIF_IMG_TAG]: NextImageOrImg,
  [MOTIF_A_TAG]: LinkOrAnchor(slugComponents),
})
