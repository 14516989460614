import * as __motif_headlessui_react from '@headlessui/react'
import __motif_markdoc from '@markdoc/markdoc'
import * as __motif_mdx_react from '@mdx-js/react'
import __motif_sindresorhus_slugify from '@sindresorhus/slugify'
import __motif_lodash_es from 'lodash-es'
import * as __motif_luxon from 'luxon'

import { getScope } from './scope'

// This one is a little subtle. The template accepts a `meta`
// prop, which is provided by the children (here, `code`), either
// as a static, pre-extracted `frontmatter`, or as a dynamic,
// runtime-executed, `meta` JS variable export. The former case
// can be dealt with simply by sending the frontmatter prop to
// the template. But for the latter case, we actually need to
// execute the `code` and get a hold of both the content component
// (exported as `default`) and the meta variable.
export function getPageComponent(mdxCode: string, ShimmedEditor?: any) {
  const fullScope = Object.assign(
    {
      opts: {
        ...__motif_markdoc,
        ...__motif_mdx_react,
        ...__motif_lodash_es,
        ...__motif_luxon,
        ...__motif_sindresorhus_slugify,
        ...__motif_headlessui_react,
        ...(ShimmedEditor ? ShimmedEditor : {}),
      },
    },
    getScope(ShimmedEditor)
  )
  const keys = Object.keys(fullScope)
  const values = Object.values(fullScope)
  const hydrateFn = Reflect.construct(Function, keys.concat(`${mdxCode}`))
  return hydrateFn.apply(hydrateFn, values)
}
