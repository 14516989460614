import type { NextRouter } from 'next/router'

export const getSlugComponents = (router: NextRouter) => {
  if (typeof router?.query?.slug === 'string') {
    return [router.query.slug]
  } else if (router?.query?.slug) {
    return router.query.slug as string[]
  }
  return []
}
