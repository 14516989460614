import type { RenderableTreeNode } from '@markdoc/markdoc'
import Markdoc from '@markdoc/markdoc'
import * as MDX from '@mdx-js/react'
import dynamic from 'next/dynamic'
import React, { useMemo } from 'react'
import * as jsxRuntime from 'react/jsx-runtime'

import type { MarkdocCodeFenceProps } from '../MarkdocCodeFence'

const MarkdocCodeFence = dynamic<MarkdocCodeFenceProps>(() =>
  import('../MarkdocCodeFence').then((mod) => mod.MarkdocCodeFence)
)

export type MarkdocComponentProps = {
  node: RenderableTreeNode
  markdocComponentsCode?: string | null
  scope: any
}

const MarkdocComponent = ({
  node,
  markdocComponentsCode,
  scope,
}: MarkdocComponentProps) => {
  const MarkdocComponents: any = useMemo(() => {
    const fullScope = Object.assign({ opts: { ...MDX, ...jsxRuntime } }, scope)
    const keys = Object.keys(fullScope)
    const values = Object.values(fullScope)
    const hydrateFn = Reflect.construct(
      Function,
      keys.concat(`${markdocComponentsCode}`)
    )
    return hydrateFn.apply(hydrateFn, values)?.default
  }, [scope, markdocComponentsCode])

  return (
    <>
      {Markdoc.renderers.react(node, React, {
        components: {
          __MotifFence__: MarkdocCodeFence,
          ...MarkdocComponents,
        },
      })}
    </>
  )
}

export default MarkdocComponent
